import { WallItemSubtype } from '@streem/sdk-types';
import { AppText, Button, Modal, Row, styled, Theme } from '@streem/ui-react';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from 'tui-image-editor';
import { FC, useRef, useEffect, ReactNode, MutableRefObject } from 'react';
import { WallItem } from 'streem-sdk-protobuf';
import { useHistory } from 'react-router-dom';
import { removeSegments } from '../../util/routing';
import appLogger from '../../util/logging/app_logger';

export const Streemshot: FC<{ artifact: WallItemSubtype<WallItem, 'streemshot'> }> = ({
    artifact,
}) => <ArtifactStreemshot data-testid="artifact" src={artifact?.downloadUrl} />;

export const Recording: FC<{ artifact: WallItemSubtype<WallItem, 'artifact'> }> = ({
    artifact,
}) => (
    <ArtifactVideo data-testid="artifact">
        <video
            key={artifact.id}
            controls
            data-testid={`artifact-${artifact.id}`}
            style={{ flexGrow: 1 }}
        >
            <source src={artifact?.recording?.downloadUrl ?? undefined} type="video/mp4" />
        </video>
    </ArtifactVideo>
);

const ArtifactStreemshot = styled.img<{ src: string }>(() => {
    return {
        width: '100%',
        height: '100vh',
        background: '#000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        objectFit: 'scale-down',
    };
});

const ArtifactVideo = styled.div(() => ({
    display: 'flex',
    height: '100%',
    background: '#000',
}));

interface Props {
    editToolbar: ReactNode;
    artifact: WallItemSubtype<WallItem, 'streemshot'>;
    imageEditor: MutableRefObject<ImageEditor>;
    saveRevision: () => void;
    setEditMode: (state: boolean) => void;
    showExitModal: boolean;
    setShowExitModal: (state: boolean) => void;
    undoStackCount: number;
    setUndoStackCount: (state: number) => void;
}

export const EditStreemshot = ({
    artifact,
    imageEditor,
    saveRevision,
    setEditMode,
    showExitModal,
    setShowExitModal,
    undoStackCount,
    setUndoStackCount,
    editToolbar,
}: Props) => {
    const ref = useRef<HTMLDivElement>(null);
    const history = useHistory();
    const log = appLogger.extend('streemshot editor');

    useEffect(() => {
        if (!ref.current) {
            return;
        }
        if (!imageEditor.current) {
            // Instantiate image editor and load the streemshot from URL
            imageEditor.current = new ImageEditor(ref.current, {
                cssMaxWidth: window.innerWidth - 450,
                cssMaxHeight: window.innerHeight - 56,
                selectionStyle: {
                    cornerSize: 10,
                    rotatingPointOffset: 70,
                    cornerColor: Theme.colors.azure,
                    borderColor: Theme.colors.white,
                },
            });
            imageEditor.current
                .loadImageFromURL(artifact.downloadUrl, 'streemshot')
                .then(() => {
                    imageEditor.current.clearUndoStack();
                })
                .catch(err => {
                    log.error('Failed to load image in streemshot editor', err);
                    setEditMode(false);
                    imageEditor.current = null;
                });
        }
        imageEditor.current.on('addText', (e: any) => {
            imageEditor.current.addText('Edit this text', {
                position: e.originPosition,
                styles: {
                    fontFamily: 'ASAP, sans-serif',
                    fill: Theme.colors.azure,
                    fontSize: 48,
                },
            });
        });
        imageEditor.current.on('undoStackChanged', length => {
            // We track the undo count ourselves so we can pass that number when we want to discard all edits
            setUndoStackCount(length);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref.current, artifact]);

    useEffect(() => {
        if (showExitModal && undoStackCount === 0) {
            setShowExitModal(false);
            history.push(removeSegments(history.location.pathname, 2));
        }
    }, [showExitModal, undoStackCount, history, setShowExitModal]);

    return (
        <>
            <EditStreemshotContainer ref={ref}>
                {imageEditor?.current && editToolbar}
            </EditStreemshotContainer>

            {showExitModal && (
                <ExitModal saveRevision={saveRevision} setShowExitModal={setShowExitModal} />
            )}
        </>
    );
};

const EditStreemshotContainer = styled.div(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    background: '#000',
    position: 'relative',
}));

const ExitModal = ({
    saveRevision,
    setShowExitModal,
}: {
    saveRevision: () => void;
    setShowExitModal: (state: boolean) => void;
}) => {
    const history = useHistory();
    return (
        <div data-testid="streemshot-editor-modal">
            <Modal
                closeOnOverlayClick={false}
                isOpen
                onClose={() => setShowExitModal(false)}
                cardProps={{
                    decoratorPosition: undefined,
                }}
                styleOverride={{
                    content: {
                        width: '512px',
                    },
                }}
            >
                <Row mb={3}>
                    <AppText as="h1" size="large" headingFontFamily>
                        Exit without saving?
                    </AppText>
                </Row>
                <Row mb={5}>
                    <AppText>Any changes will be lost.</AppText>
                </Row>
                <Row justifyContent="flex-end">
                    <Button
                        mr={Theme.spacing.s}
                        onClick={() => history.push(removeSegments(history.location.pathname, 2))}
                        variant="inline"
                    >
                        Exit without saving
                    </Button>
                    <Button onClick={saveRevision}>Save and exit</Button>
                </Row>
            </Modal>
        </div>
    );
};
