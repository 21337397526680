import { FC, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import {
    BannerNotification,
    GlobalStyles,
    LoadingOverlay,
    Row,
    styled,
    ThemeProvider,
    Toaster,
    ToastTypes,
} from '@streem/ui-react';
import { ErrorBoundary } from './util/error/error_boundary';
import { GlobalStoreProvider, useInitializeGlobalStores } from './hooks/use_global_context';
import { Observer } from 'mobx-react';
import UnavailablePage from './pages/authentication/unavailable_page';
import { BaseRoutes } from './routing/routes';
import { VerticalNavBar } from './components/vertical_nav_bar/vertical_nav_bar';
import { TopNavBar } from './components/top_nav_bar/top_nav_bar';
import { InCallListener } from './components/calls/in_call_listener';
import { AnonymousRoute } from './routing/anonymous_route';
import { LoginPage } from './pages/authentication/login_page';
import { NotFoundRoute } from './pages/authentication/not_found_page';
import { ProtectedRoute } from './routing/protected_route';
import { TermsAndConditionsPage } from './pages/authentication/terms_and_conditions_page';
import { DeviceUpdateNotification } from './components/device_update_notification';
import { SurveyForm } from './components/calls/survey_form';
import config from './config';
import { RolesAuth } from './components/authorization/roles_auth';
import { VersionCheck } from './components/version_check';
import { InactivityMonitor } from './components/inactivity_monitor';
import { ForgotPasswordPage } from './pages/authentication/forgot_password_page';
import { createGetCompanyStore, GetCompanyProvider } from './hooks/detail_store_hooks';
import { CookiesDisabledPage } from './pages/authentication/cookies_disabled_page';
import {
    createGetCompanyCallLogStore,
    createGetUserCallLogStore,
    createGetUserFavoritesCallLogStore,
    GetCompanyCallLogProvider,
    GetUserCallLogProvider,
    GetUserFavoritesCallLogProvider,
} from './hooks/list_store_hooks';
import { autorun } from 'mobx';
import { IFrameLogoutMonitor } from './components/iframe_logout_monitor';
import { setIsEmbedWhenExpertOnEmbedRoutes } from './util/set_is_embed';
import { SessionExpiredRoute } from './pages/authentication/session_expired_route';
import { useAnonymousShareChecker } from './hooks/use_anonymous_share_checker';
import appLogger from './util/logging/app_logger';
import { AllowNotificationsModalOnce } from './components/allow_notifications/allow_notifications_modal';
import { getPermissionSettings } from './util/browser_notification_settings';
import { audio } from '@streem/sdk-react';

const App: FC = () => {
    const globalStores = useInitializeGlobalStores();
    const { authStore, configStore, companySettingsStore, uiStore, sdkStore } = globalStores;
    const companyStore = createGetCompanyStore();
    const companyCallLogStore = createGetCompanyCallLogStore();
    const userCallLogStore = createGetUserCallLogStore();
    const userFavoritesCallLogStore = createGetUserFavoritesCallLogStore();
    const isAnonymousSharePage = useAnonymousShareChecker(authStore);
    const isEmbedRoute =
        window.location.pathname.startsWith('/embed') ||
        window.location.pathname.startsWith('/streems');

    useEffect(() => {
        if (!authStore.initialized || configStore.loading || configStore.lastError) return;
        const promises = [companyStore.fetch(config.companyCode)];
        return () => promises.forEach(p => p.cancel());
    }, [companyStore, authStore.initialized, configStore.loading, configStore.lastError]);

    useEffect(() => {
        const handleAudioOutputDeviceChanged = (audioOutputDevice: MediaDeviceInfo) => {
            // do not show toast while on a call
            if (!sdkStore.roomSession?.current) {
                uiStore.addToastWithManualClose({
                    content: `Speaker changed to ${audioOutputDevice.label}`,
                    flavor: ToastTypes.INFO,
                    id: `audio-output-changed`,
                });
            }
        };

        audio.initialize(handleAudioOutputDeviceChanged);
    }, [uiStore, sdkStore]);

    // Heartbeat log every 5min
    useEffect(() => {
        const timer = setInterval(() => {
            appLogger.info(
                `Heartbeat, local time: ${new Date().toISOString()}, window state: ${
                    document.visibilityState
                }`,
            );
        }, 1000 * 60 * 5);

        return () => clearInterval(timer);
    }, []);

    /**
     * Wait for userId to exist on auth store, then fetch company settings
     * using 'autorun' which is provided by mobx for when a hook depends on an observable
     */
    useEffect(() => {
        autorun(() => {
            if (!authStore.userId) return;
            const promise = companySettingsStore.loadSettings();
            return () => promise.cancel();
        });
    }, [authStore.userId, companySettingsStore]);

    /**
     * Wait for userId to exist on auth store, then check notification permission
     * and log it.
     */
    useEffect(() => {
        autorun(() => {
            if (!authStore.userId) return;
            appLogger.info(
                `Web Browser Notification permissionStatus=${getPermissionSettings()} for user ${
                    authStore.userId
                }.`,
            );
        });
    }, [authStore.userId]);

    return (
        <ErrorBoundary>
            <ThemeProvider>
                <GlobalStoreProvider value={globalStores}>
                    <GetCompanyProvider value={companyStore}>
                        <GetCompanyCallLogProvider value={companyCallLogStore}>
                            <GetUserCallLogProvider value={userCallLogStore}>
                                <GetUserFavoritesCallLogProvider value={userFavoritesCallLogStore}>
                                    <GlobalStyles />
                                    <BrowserRouter>
                                        <Observer>
                                            {() => {
                                                if (!window.navigator.cookieEnabled) {
                                                    return <CookiesDisabledPage />;
                                                }
                                                if (!authStore.initialized || configStore.loading) {
                                                    return <LoadingOverlay />;
                                                }
                                                if (
                                                    configStore.lastError ||
                                                    companySettingsStore.lastError
                                                ) {
                                                    return <UnavailablePage />;
                                                }

                                                setIsEmbedWhenExpertOnEmbedRoutes(
                                                    window.location.pathname,
                                                );

                                                return (
                                                    <>
                                                        <Switch>
                                                            <AnonymousRoute
                                                                path={'/login'}
                                                                exact={true}
                                                                component={LoginPage}
                                                            />
                                                            <AnonymousRoute
                                                                path="/forgot"
                                                                exact={true}
                                                                component={ForgotPasswordPage}
                                                            />
                                                            <Route
                                                                path="/404"
                                                                exact={true}
                                                                component={NotFoundRoute}
                                                            />
                                                            <Route
                                                                path="/session-expired"
                                                                exact={true}
                                                                component={SessionExpiredRoute}
                                                            />
                                                            <ProtectedRoute
                                                                path="/termsandconditions"
                                                                exact={true}
                                                                component={TermsAndConditionsPage}
                                                                only={[
                                                                    'SUPER_ADMIN',
                                                                    'COMPANY_ADMIN',
                                                                    'AGENT',
                                                                ]}
                                                            />
                                                            <Route>
                                                                <TopNavBar />
                                                                <AppWrapper
                                                                    isEmbed={isEmbedRoute}
                                                                    isAnonymousSharePage={
                                                                        isAnonymousSharePage
                                                                    }
                                                                >
                                                                    <VerticalNavBar />
                                                                    <MainSectionWrapper
                                                                        expanded={
                                                                            uiStore.mainNavExpanded
                                                                        }
                                                                        isEmbed={isEmbedRoute}
                                                                        isAnonymousSharePage={
                                                                            isAnonymousSharePage
                                                                        }
                                                                    >
                                                                        <BannerNotification
                                                                            content={
                                                                                uiStore.bannerNotificationContent
                                                                            }
                                                                        />
                                                                        <BaseRoutes />
                                                                    </MainSectionWrapper>
                                                                    <RolesAuth except="ANONYMOUS">
                                                                        <SurveyForm />
                                                                        <InCallListener />
                                                                        <InactivityMonitor />
                                                                    </RolesAuth>
                                                                    {sdkStore.userSession
                                                                        .current && (
                                                                        <DeviceUpdateNotification
                                                                            currentUserSession={
                                                                                sdkStore.userSession
                                                                                    .current
                                                                            }
                                                                        />
                                                                    )}
                                                                    <IFrameLogoutMonitor />
                                                                </AppWrapper>
                                                            </Route>
                                                        </Switch>
                                                        <Toaster
                                                            content={uiStore.toasts.slice()}
                                                            onClose={toastId =>
                                                                uiStore.removeToast(toastId)
                                                            }
                                                            onPauseToastTimeout={toastId =>
                                                                uiStore.pauseRemoveToast(toastId)
                                                            }
                                                            onResumeToastTimeout={(
                                                                toastId,
                                                                timeout,
                                                            ) =>
                                                                uiStore.removeToastViaTimeout(
                                                                    toastId,
                                                                    timeout,
                                                                )
                                                            }
                                                        />
                                                        <VersionCheck
                                                            roomId={
                                                                sdkStore.roomSession?.current
                                                                    ?.roomId
                                                            }
                                                        />
                                                        <AllowNotificationsModalOnce />
                                                    </>
                                                );
                                            }}
                                        </Observer>
                                    </BrowserRouter>
                                </GetUserFavoritesCallLogProvider>
                            </GetUserCallLogProvider>
                        </GetCompanyCallLogProvider>
                    </GetCompanyProvider>
                </GlobalStoreProvider>
            </ThemeProvider>
        </ErrorBoundary>
    );
};

export default App;

const MainSectionWrapper = styled.div<{
    expanded: boolean;
    isEmbed: boolean;
    isAnonymousSharePage: boolean;
}>(({ expanded, isEmbed, isAnonymousSharePage }) => ({
    width: `calc(100vw - ${isEmbed || isAnonymousSharePage ? '0' : expanded ? '212' : '56'}px)`,
    height: '100%',
    overflowY: 'auto',
}));

const AppWrapper = styled(Row)<{ isEmbed: boolean; isAnonymousSharePage: boolean }>(
    ({ isEmbed, isAnonymousSharePage }) => ({
        maxWidth: '100vw',
        height: `calc(100vh - ${isEmbed || isAnonymousSharePage ? '0' : '72'}px)`,
    }),
);
